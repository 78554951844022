<template>
  <div class="home">

  </div>
</template>

<script>

import { post, get } from '@/api/index'
export default {
  name: 'Home',
  components: {

  },
  created () {
    console.log("---111---");
    console.log(this.$route.params)
    if (this.$route.params.brandId && this.$route.params.userId) {
      window.location = 'https://znw.farmtruth.com/uni-h5/pages/brand/index?id=' + this.$route.params.brandId + '&isPerson=1&userId=' + this.$route.params.userId + '&isLing=1&showGoods=1'
    }
  }
}
</script>
<style scoped lang="scss">
.home {
}
</style>
