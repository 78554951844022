import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/home/:brandId/:userId/*',
    name: 'Home',
    component: Home
  },


  //   import commercial from '../views/residential/commercial.vue'
  // import distributed from '../views/residential/distributed.vue'
  // import microgrid from '../views/residential/microgrid.vue'
  // import PvEv from '../views/residential/pv_ev_ess.vue'
  // import SolarWindPower from '../views/residential/solar_wind_power.vue'
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    console.log(savedPosition)
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

export default router
