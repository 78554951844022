import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/font.css'
import './assets/css/index.css'
import './assets/css/main.scss'

import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
Vue.use(mavonEditor)

Vue.config.productionTip = false

const baseSize = 16
function setRem () {
  const scale = document.documentElement.clientWidth / 750
  document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'
}
setRem()
window.onresize = function () {
  setRem()
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
